import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapWebMobilePage');

    const iconsOnlyFooterComponent = [{
        icon: 'icon-mobile',
        text: t('DESCRIPTION_MOBILE_USEFUL_INFORMATION')
    }, {
        icon: 'icon-checkmark',
        text: t('DESCRIPTION_SESSIONS')
    }];

    const icons = [{
        icon: 'icon-mobile1',
        text: t('TITLE_ICON_SDK')
    }, {
        icon: 'icon-mobile-devices',
        text: t('TITLE_ICON_JS_SDK')
    },
    {
        icon: 'icon-plugin',
        text: t('TITLE_ICON_REACT_NATIVE')
    },
    {
        icon: 'icon-map5',
        text: t('TITLE_ICON_MOBILE_INNOVATION')
    }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_WEB_MOBILE')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_WEB_MOBILE')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos title={t('TITLE_THEY_USE_WEB_MOBILE')}
                        size='large'
                        customersLogos={[
                            {image: '/images/products/wemap-web/france-3.svg'},
                            {image: '/images/products/wemap-web/air-france.svg'},
                            {image: '/images/products/wemap-web/hachette-tourisme.svg'},
                            {image: '/images/products/wemap-pro/depeche.svg'},
                            {image: '/images/products/wemap-web/logo_uh.png'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BEST_WEB_USER')}
                        cellParagraph={t('DESCRIPTION_BEST_UX')}
                        iconsOnlyFooterComponent={iconsOnlyFooterComponent}
                        pictureRight={data.mobilePhones.childImageSharp}
                        altDescription='mobiles with open detaled pinpoints and a Wemap map'
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_MOBILE_FULL_WEBAPP')}
                            text={t('DESCRIPTION_USEFUL_INFORMATION')}
                            titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BUILD_MAP_USER')}
                        cellParagraph={t('DESCRIPTION_OFFER_SIMPLE')}
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_PLEASANT_USEFUL')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_PROVEN_SESSION')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_MAXIMISE_AUDITION')
                            }
                        ]}
                        pictureRight={data.thonon.childImageSharp}
                        altDescription='mobile and iPad view in detailed open pinpoint on Wemap map'
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_BUILD_INNOVATION')}
                            text={t('DESCRIPTION_ONLINE_TOOL_CREATE')}
                            titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                            icons={[
                                {
                                    icon: 'icon-palette',
                                    text: t('TITLE_ICON_TAILORED')
                                },
                                {
                                    icon: 'icon-center_focus_strong',
                                    text: t('TITLE_ICON_AUGMENTED_REALITY')
                                },
                                {
                                    icon: 'icon-checkmark',
                                    text: t('TITLE_ICON_OFFLINE')
                                },
                                {
                                    icon: 'icon-embed2',
                                    text: t('DESCRIPTION_AVAILABLE_SDK')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_LEVERAGE_POWER')}
                        pictureRight={data.webApps.childImageSharp}
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        widthPicture='400px'
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_CONNECT_CONTENT')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_CREATE_DEPLOY_MAP')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_CONTENT_SERVICE')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE')
                            }
                        ]}
                    />

                    <BannerPicture backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with view of Wemap map open on computer'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  banner: file(relativePath: {eq: "products/wemap-web/banner-wemap-web.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  thonon: file(
    relativePath: {eq: "products/wemap-web/wemap-web-thonon-les-bains-mobile.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 550, layout: FIXED)
    }
  }
  mobilePhones: file(
    relativePath: {eq: "products/wemap-web/wemap-mobile-three-iphones.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 426, layout: FIXED)
    }
  }
  uniqueHeritages: file(
    relativePath: {eq: "industries/culture/wemap-unique-heritages.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, height: 470, layout: FIXED)
    }
  }
}
`;
